::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: '#F4F6F8';
}

::-webkit-scrollbar-thumb {
  background: #919eab;
  border-radius: 6px;
  border: 2px solid #f4f6f8;
}

@media print {
  .print-container {
    display: block !important;
  }

  .page-break {
    page-break-after: always;
    break-after: always;
  }
}

.default-scrollbar {
  overflow-y: scroll !important;
}
